import _ from 'underscore';
import { Formatter } from '@bingads-webui/listener';

export class OneDSPropertyFormatter extends Formatter {
  format(logMessage) {
    return _.mapObject(logMessage, val => ({
      value: val,
    }));
  }
}
