import _ from 'underscore';
import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { Listener } from '@bingads-webui/listener';
import { defaultConfig } from './default-config';

/**
 * Class of OneDS listener
 */
export class OneDSListener extends Listener {
  constructor(options = {}) {
    super(options);
    this.options = _.defaults(options, {
      transmitProfile: defaultConfig.transmitProfile,
      batchSize: defaultConfig.batchSize,
    });
    this.formatters = _.defaults(this.options.formatters || {}, defaultConfig.formatters);
    this.filters = this.options.filters || [];
    this.logTableNames = this.options.logTableNames;
    this.defaultLogCategory = this.options.defaultLogCategory;
  }

  /**
   * init
   * @returns {undefined} - no returns
   */
  init() {
    if (!this.options.tenantToken || this.initialized) {
      return;
    }

    // Setup Telemetry
    const analytics = new ApplicationInsights();
    const config = {
      instrumentationKey: this.options.tenantToken,
      channelConfiguration: {
        autoFlushEventsLimit: this.options.batchSize,
      },
      webAnalyticsConfiguration: {
        autoCapture: {
          pageView: true,
          onLoad: true,
          onUnload: true,
        },
      },
    };
    // Initialize SDK
    analytics.initialize(config, []);

    this.postChannel = analytics.getPostChannel();
    // eslint-disable-next-line no-underscore-dangle
    this.postChannel._setTransmitProfile(this.options.transmitProfile);

    this.analytics = analytics;

    this.initialized = true;
  }

  /**
   * flushes logs to Aria and tears down the logger
   * @returns {undefined} - no returns
   */
  teardown() {
    if (!this.initialized) {
      return;
    }
    this.initialized = false;

    this.analytics.unload();
  }

  /**
   * writes logs to the listener
   * @param {object} logMessage - logs to be written
   * @returns {undefined} - no returns
   */
  write(logMessage) {
    if (!this.initialized || this.filterLogs(logMessage)) {
      return;
    }

    const categoryName = this.getCategoryName(logMessage.logCategory);
    const formattedMsg = this.formatLogs(logMessage);

    this.analytics.trackEvent({
      name: this.logTableNames[categoryName].name,
      data: formattedMsg,
    });
  }

  /**
   * @private
   * @param {object} logCategory - logs to be written
   * @returns {undefined} - no returns
   */
  getCategoryName(logCategory) {
    const isValidLogCategory = _.has(this.logTableNames, logCategory);
    return isValidLogCategory ? logCategory : this.defaultLogCategory;
  }
}
